<template>
    <div>
        <div class="tile is-ancestor">
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Name') }}: {{ bookMagazine.name }}
                    </p>
                </article>
            </div>
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('State') }}: {{ bookMagazine.state }}
                    </p>
                </article>
            </div>
        </div>
        <div class="tile is-ancestor">
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Program') }}: {{ bookMagazine.program }}
                    </p>
                </article>
            </div>
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Property') }}: {{ bookMagazine.userProperty }}
                    </p>
                </article>
            </div>
        </div>
        <div class="tile is-ancestor">
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Segmentation') }}: {{ bookMagazine.segmentation }}
                    </p>
                </article>
            </div>
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Position') }}: {{ bookMagazine.position }}
                    </p>
                </article>
            </div>
        </div>
        <UserIndex :pages="pages"/>
    </div>
</template>

<script>
import UserIndex from './UserIndex.vue';

export default {
    name: 'Show',

    components: { UserIndex },

    inject: ['i18n', 'http', 'route', 'routerErrorHandler', 'toastr'],

    data: () => ({
        bookMagazine: {},
        bookMagazineImages: {},
        pages: [],
    }),

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.http.get(this.route('bookMagazine.show', this.$route.params.bookMagazine))
                .then(({ data }) => {
                    this.bookMagazine = data.bookMagazine;
                    this.bookMagazineImages = data.bookMagazine.book_magazine_images;
                    this.bookMagazineImages.forEach(bookMagazineImage => {
                        this.pages.push(this.route('bookMagazineImages.downloadImage', bookMagazineImage.id));
                    });
                }).catch(this.errorHandler);
        },
    },
};
</script>

<style lang="scss">
@import '../../../sass/enso.scss';

.title-menu {
    font-size: 1.5rem;
    text-align: center;
    font-family: Bahnschrift,serif;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1.2px;
    color: $secondary;
}

.section-style{
    background: radial-gradient(circle, rgba(72,95,199,1) 35%, rgba(9,9,121,1) 100%);
    padding: 10px;
}

.titles{
    font-family: Bahnschrift,serif;
    text-transform: capitalize;
    font-weight: bold;
    color: $secondary;
}

.titles1{
    text-align: center;
    font-family: Bahnschrift,serif;
    text-transform: capitalize;
    font-weight: bold;
    color: $secondary;
}

.space{
    padding: 3px !important;
    &.btn {
        cursor: pointer;
    }
}

.butt{
    background-color: $orange;
    border-color: transparent;
    color: $secondary !important;
}

.i-icon {
    margin-left: 5px;
}

.textEmail{
    text-transform: none !important;
}

</style>
